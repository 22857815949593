/* .hover-col .card-container {
  transition: transform 0.3s ease-in-out;
}

.hover-col .card-container:hover {
  transform: scale(1.05);
  cursor: pointer;
} */

.transition-col {
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

.card-container {
  transition: transform 0.3s ease-in-out;
}

/* .card-container:hover {
  transform: scale(1.02);
} */

.scrollable-div {
  height: 70vh; /* Adjust the height as needed */
  overflow-y: auto;
  padding: 10px;
}


.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .scrollable-div {
    max-height: 300px; /* Adjust for smaller screens */
  }
}
.cardTiming {
  text-align: end;
  top: 1px;
  font-size: 11px;
  position: absolute;
  width: 100%;
  padding-right: 10px;
}
.cardHight{
height: 206px !important;
overflow: hidden;
}
.cardNormal{
    height: auto;
}